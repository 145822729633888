import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  apiUrl,
  sendEnqueryEmail,
  validateAccessToken,
  authorize_email
} from '../services/commonService';
import ErrorLabel from './Validation/ErrorLabel';
import {
  ucFirst,
  validateAlphabet,
  validateCommaSeparatedEmails,
  validateEmail,
} from '../assets/js/validator';
import ModalBox from './Modal/ModalBox';
import './Enquiry.css';
import { Helmet } from 'react-helmet';
import RecaptchaV2 from './recaptchaV2';
import CommonModalBox from './Modal/CommonModalBox';
import { BsChevronDown, BsChevronUp, BsXLg } from 'react-icons/bs';

export default function Enquiry(props: any) {
  const callLoginUrl = () => {
    window.location.href = `${apiUrl()}okta/login`;
  };
  const [userDetails] = useState<any | null>(
    sessionStorage.getItem('UsersDetails')
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAuthPopup, setShowAuthPopup] = useState<boolean>(false);
  const [saveProfile, setSaveProfile] = useState<boolean>(false);
  const [hasProxyUserVerified, setHasProxyUserVerified] = useState<boolean>(false);
  const [proxyUserPopupOpen, setProxyUserPopupOpen] = useState<boolean>(false);
  const [additionalEmailList, setAdditionalEmailList] = useState<string[]>([]);
  const [showAllEmail, setShowAllEmail] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState({
    open: false,
    type: '',
  });

  const [inputValues, setInputValues] = useState<any | ''>({
    firstName: JSON.parse(userDetails)?.given_name
      ? JSON.parse(userDetails)?.given_name
      : '',
    lastName: JSON.parse(userDetails)?.family_name
      ? JSON.parse(userDetails)?.family_name
      : '',
    email: JSON.parse(userDetails)?.email ? JSON.parse(userDetails)?.email : '',
    additionalEmail: '',
    additionalEmailList: additionalEmailList,
    issueCategory: '',
    issueSubCategory: '',
    description: '',
    proxyEmail: ''
  });

  const [error, setError] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    additionalEmail: '',
    issueCategory: '',
    issueSubCategory: '',
    description: '',
    proxyEmail: ''
  });

  const [rules, setRules] = useState<any>({
    firstName: {
      required: true,
      alphabets: false,
      minlength: 1,
      maxlength: 50,
      fieldName: 'First Name',
    },
    lastName: {
      required: true,
      alphabets: false,
      minlength: 1,
      maxlength: 50,
      fieldName: 'Last Name',
    },
    email: { required: true, email: true, fieldName: 'Email', maxlength: 40 },
    proxyEmail: { required: true, proxyEmail: true, fieldName: 'Proxy Email', maxlength: 40 },
    additionalEmail: {required: false, additionalEmail: true, fieldName: 'Additional Email Recipients'},
    issueCategory: {
      required: true,
      fieldName: 'Issue Category',
    },
    issueSubCategory: {
      required: true,
      fieldName: 'Issue Subcategory',
    },
    description: {
      required: false,
      alphabets: false,
      minlength: 4,
      maxlength: 32000,
      fieldName: 'Description',
    },
  });

  const [checkLogin, setCheckLogin] = useState<any>('');

  const [showNotification, setNotification] = useState<Boolean>(false);
  const [showSubLinks, setSubLinks] = useState<any>('');
  const [showValue, setValue] = useState<any>('');
  const [captchaValue, setcaptchaValue] = useState<any>('');
  const [captchaVersion, setcaptchaVersion] = useState<any>(3);
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(value);
    if (name === 'issueCategory') {
      inputValues.issueSubCategory = '';
      inputValues.description = '';
      error.issueSubCategory = '';
      // error.issueSubCategory = 'Please Enter ' + rules[name]?.fieldName;
      error.description = 'Please Enter Description';
      // if (value !== 'Other Categories') {
      //   error.description = '';
      // }
      setNotification(false)
    }

    if (value === 'Networking Academy' || value === 'Training and Certification (Learning@Cisco)' || value === 'Contract Changes and Updates') {
      setNotification(true)
      setSubLinks(getsubCategoryLink(event.target.value));
    } else {
      setNotification(false)
    }

    if (value === 'Cisco.com Profile / Account and Password Issues' || value === 'Contracts and Contract Association') {
      setShowInfoPopup(true);
    } else {
      setShowInfoPopup(false);
    }

    setInputValues({ ...inputValues, [name]: value.trimStart() });

    if (rules[name].required === true && value === '') {
      setError({ ...error, [name]: 'Please Enter ' + rules[name]?.fieldName });
    } else if ((rules[name]?.email || rules[name]?.additionalEmail) && !validateCommaSeparatedEmails(value)) {
      setError({ ...error, [name]: 'Please Enter Valid Email' });
    } else if (rules[name]?.proxyEmail && !validateEmail(value)) {
      setError({ ...error, [name]: 'Please Enter Valid Email' });
      setDialogState({ open: false, type: '' });
    } else if (
      rules[name].alphabets &&
      !validateAlphabet(value) &&
      value !== ''
    ) {
      setError({ ...error, [name]: 'Only alphabets are allowed here' });
    } else if (
      rules[name]?.minlength &&
      value.length < rules[name]?.minlength &&
      value !== ''
    ) {
      setError({
        ...error,
        [name]:
          ucFirst(rules[name]?.fieldName) +
          ' is too short to be valid,minimum field length is ' +
          rules[name]?.minlength,
      });
    } else if (
      rules[name]?.maxlength &&
      value.length > rules[name]?.maxlength &&
      value !== ''
    ) {
      setError({
        ...error,
        [name]:
          ucFirst(rules[name]?.fieldName) +
          ' is too long to be valid,maximum field length is ' +
          rules[name]?.maxlength,
      });
    } else {
      setError({ ...error, [name]: '' });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault();

      if (validateEmail(inputValues.additionalEmail) && !additionalEmailList.includes(inputValues.additionalEmail) && inputValues.additionalEmail !== inputValues.email) {
        const updatedEmailList = [...additionalEmailList, inputValues.additionalEmail];
        setAdditionalEmailList(updatedEmailList);
        setInputValues({ ...inputValues, additionalEmail: '', additionalEmailList: updatedEmailList});
      }
    }
  };

  const handleRemoveEmail = (e: any, index: number) => {
    e.preventDefault();
    let emails = additionalEmailList;
    emails.splice(index, 1);
    setAdditionalEmailList([...emails]);
  }

  const hasVerifiedEmail = () => {
    if (dialogState.type !== 'verified') {
      setShowAuthPopup(true);
      setSaveProfile(true);
    } else {
      handleForm();
    }
  }

  const handleForm = () => {
    setShowAuthPopup(false);
    let validateOk = true;
    if (
      inputValues.issueCategory === 'Other Categories' &&
      inputValues.description === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.issueCategory !== 'Other Categories' &&
      inputValues.issueSubCategory === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.firstName !== '' &&
      inputValues.lastName !== '' &&
      inputValues.email !== '' &&
      inputValues.issueCategory !== '' &&
      error.firstName === '' &&
      error.lastName === '' &&
      error.email === '' &&
      error.additionalEmail === '' &&
      error.description === '' &&
      validateOk === true
    ) {
      setcaptchaVersion(3) /* Enable captcha v3 */
      sendEmail()
      setSaveProfile(false);
    } else {
      //console.log("Failed to validate form");
    }
  };

  const validateForm = () => {
    let validateOk = true;
    if (
      inputValues.issueCategory === 'Other Categories' &&
      inputValues.description === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.issueCategory !== 'Other Categories' &&
      inputValues.issueSubCategory === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.firstName !== '' &&
      inputValues.lastName !== '' &&
      inputValues.email !== '' &&
      inputValues.issueCategory !== '' &&
      error.firstName === '' &&
      error.lastName === '' &&
      error.email === '' &&
      error.additionalEmail === '' &&
      error.description === '' &&
      validateOk === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  const getsubCategoryLink = (Links: string | undefined) => {
    switch (Links) {
      case "Contract Changes and Updates":
        return 'https://customerservice.cloudapps.cisco.com'
      case "Networking Academy":
        return "https://www.netacad.com/support/"
      case "Training and Certification (Learning@Cisco)":
        return 'https://certsupport.cisco.com/'
    }
  }

  // Define function that will open the modal
  const handleOpen = (val: boolean) => {
    setIsModalOpen(val);
    if (!val) {
      /* Reset Captcha. Reload Captcha V3 */
      setcaptchaVersion(3);
      refreshCaptchaV3Token()
    }
  };

  const handleDialogState = (val: any) => {
    if (val.type === 'verified') {
      setDialogState({ open: val.open, type: 'verified' });
      setSaveProfile(false);
    } else {
      setDialogState({ open: val.open, type: '' });
    }
  }

  const handleInfoModal = (val: boolean) => {
    setShowInfoPopup(val);
  }

  const handleCaptcha = (token: any) => {
    setcaptchaValue(token);
  }

  useEffect(() => {
    if (captchaVersion === 2) {
      if (validateForm()) {
        enabledButton();
      } else {
        disabledButton();
      }
    }
  }, [captchaValue]);

  const disabledButton = () => {
    let element = document.getElementById('btn_submit') as HTMLInputElement;
    if (element) {
      element.classList.add('muse-disabled-button');
      element.disabled = true;
    }
  };
  const enabledButton = () => {
    let element = document.getElementById('btn_submit') as HTMLInputElement;
    if (element) {
      element.classList.remove('muse-disabled-button');
      element.disabled = false;
    }
  };

  const sendEmail = async () => {
    disabledButton();
    try {
      const payload = {
        ...inputValues,
        description: inputValues.description.replace(/\n/g, '<br>'),
        additionalEmailList: inputValues.additionalEmail 
          ? [...additionalEmailList, ...inputValues.additionalEmail.split(','), inputValues.email] 
          : [...additionalEmailList, inputValues.email]
      };
      const popularFaqData = await sendEnqueryEmail(payload, captchaValue, captchaVersion, dialogState.type);
      if (
        popularFaqData.status === 200 &&
        popularFaqData.data.Message === 'Email Sent Successfully'
      ) {
        setAdditionalEmailList([]);
        setInputValues({
          firstName: JSON.parse(userDetails)?.given_name
            ? JSON.parse(userDetails)?.given_name
            : '',
          lastName: JSON.parse(userDetails)?.family_name
            ? JSON.parse(userDetails)?.family_name
            : '',
          email: JSON.parse(userDetails)?.email
            ? JSON.parse(userDetails)?.email
            : '',
          additionalEmail: '',
          issueCategory: '',
          issueSubCategory: '',
          description: '',
        });
        handleOpen(true);
        setcaptchaVersion(3);
        setShowAllEmail(false);
        setHasProxyUserVerified(false);
      } else {
        //
        /* Enable captcha v2 */
        if (captchaVersion === 2) {
          alert(popularFaqData.data.Message);
          setcaptchaVersion(3);
          refreshCaptchaV3Token()
        } else {
          //console.log("Shifting to captcha v2")
          setcaptchaVersion(2);
        }
      }
    } catch (error) {
      if (captchaVersion === 2) {
        console.warn('Error in sending mail, please try after sometime. ', error);
        alert('Error in sending mail, please try after sometime.');
        setcaptchaVersion(3);
        refreshCaptchaV3Token()
      } else {
        setcaptchaVersion(2);
      }
    }
  };

  useEffect(() => {
    setRules({
      ...rules,
      description: {
        required: true,
        alphabets: false,
        minlength: 4,
        maxlength: 32000,
        fieldName: 'Description',
      },
    });
  }, [inputValues]);

  useEffect(() => {
    let validateOk = true;
    if (
      inputValues.issueCategory === 'Other Categories' &&
      inputValues.description === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.issueCategory !== 'Other Categories' &&
      inputValues.issueSubCategory === ''
    ) {
      validateOk = false;
    }

    if (
      inputValues.firstName !== '' &&
      inputValues.lastName !== '' &&
      inputValues.email !== '' &&
      inputValues.issueCategory !== '' &&
      error.firstName === '' &&
      error.lastName === '' &&
      error.email === '' &&
      error.additionalEmail === '' &&
      error.description === '' &&
      validateOk === true &&
      showNotification !== true
    ) {
      enabledButton();
    } else {
      disabledButton();
    }
    setCheckLogin(sessionStorage.getItem('accessToken'));
  }, [inputValues.issueCategory, inputValues.description, inputValues.issueSubCategory, inputValues.firstName, inputValues.lastName, inputValues.email, error.firstName, error.lastName, error.email, error.description]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 1000);
    setTimeout(() => validateToken(), 1000);
    setcaptchaValue(props.captchaToken);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.add('keyboard-focused');
      }
    };

    const handleMouseDown = () => {
      document.body.classList.remove('keyboard-focused');
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const validateToken = async () => {
    try {
      await validateAccessToken();
      if (sessionStorage.getItem('accessToken')) setDialogState({ open: false, type: 'verified' });
    } catch (error) {
      console.warn('Anauthorised ', error);
      sessionStorage.clear();
      window.location.href = `${apiUrl()}okta/login`;
    }
  };

  const requestOTPValidation = async () => {
    try {
      const params = { email_address: inputValues.email };
      const data = await authorize_email(params);

      if (data && data.status === 200) {
        setDialogState({ open: true, type: '' });
        setShowAuthPopup(false);
      } else {
        setDialogState({ open: false, type: '' });
        alert('OTP not sent, please try again');
      }
    } catch (error) {
      setDialogState({ open: false, type: '' });
      console.error('error ', error);
    }
  };

  const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" role="img">
      <path
        fill="inherit"
        d="M6.375.75a5.625 5.625 0 1 0 0 11.25 5.625 5.625 0 0 0 0-11.25Zm.375 9H6V9h.75v.75Zm0-2.25H6V3.75h.75V7.5Z"
      />
    </svg>
  );

  const renderButton = () => (
    <div>
      <button onClick={handleForm} className='muse-button-secondary muse-button-secondary--small cursor-pointer muse-mr-30'>Submit</button>
      <button onClick={requestOTPValidation} className='muse-button-primary muse-button-primary--small cursor-pointer'>Authenticate</button>
    </div>
  )

  const renderEnquiryModal = (action: any) => {
    switch (action) {
      case 'button':
        return (
          <button onClick={() => handleOpen(false)} className='muse-button-primary cursor-pointer' tabIndex={0}>
            Close
          </button>
        )
      case 'title':
        return ('Submitted Successfully')
      case 'content':
        return (
          <div className='muse-mt-50'>
            <p className='muse-text-style-body'>
              Thank you for submitting the inquiry.
            </p>
            <div className='muse-text-style-body-reduced muse-mt-50'>
              We will get back to you soon!
            </div>
          </div>
        )
    }
  }

  const showValidateUser = () => {
    return (
      <div className="muse-input--error muse-mt-10">
        <div className="muse-input-caption">
          <ErrorIcon />
          <span>Please authenticate the mail ID. <u onClick={requestOTPValidation} className="muse-input-link">Click here</u></span>
        </div>
      </div>
    )
  }

  const handleProxyUser = (action: string) => {
    setcaptchaVersion(3);

    switch (action) {
      case 'Save':
        if (validateEmail(inputValues.proxyEmail)) {
          setInputValues({ ...inputValues, email: inputValues.proxyEmail, firstName: '', lastName: '' });
          setProxyUserPopupOpen(false);
          setSaveProfile(true);
          setHasProxyUserVerified(true);
          setDialogState({ open: false, type: '' });
          toggleFields(true); //disabled first name and last name
        }
        break;

      case 'Close':
        setInputValues({ ...inputValues, proxyEmail: '' });
        setError({ ...error, proxyEmail: '' });
        setProxyUserPopupOpen(false);
        break;

      case 'Remove':
        setError({ firstName: '', lastName: '' });
        setSaveProfile(false);
        setHasProxyUserVerified(false);
        setInputValues({ ...inputValues, email: JSON.parse(userDetails)?.email, firstName: JSON.parse(userDetails)?.given_name, lastName: JSON.parse(userDetails)?.family_name, proxyEmail: '' });

        toggleFields(false); //Enable first name and last name
        break;
    }
  }

  const toggleFields = (disable: boolean) => {
    let firstNameField = document.getElementById('firstName') as HTMLInputElement;
    let lastNameField = document.getElementById('lastName') as HTMLInputElement;

    const action = disable ? 'removeAttribute' : 'setAttribute';

    if (firstNameField && lastNameField) {
      firstNameField[action]('disabled', 'true');
      lastNameField[action]('disabled', 'true');
      firstNameField.parentElement!.style.backgroundColor = disable ? 'white' : '#f9f8f8';
      lastNameField.parentElement!.style.backgroundColor = disable ? 'white' : '#f9f8f8';
    }
  };

  function refreshCaptchaV3Token() {
    props.refreshCaptcha(true);
  }

  return (
    <>
      <Helmet>
        <title>Web Help Portal</title>
      </Helmet>
      <div className='container'>
        <div className='row padding160 mobileViewEnquiry'>
          <div
            className='muse-mt-60 muse-mb-100'
            style={{ display: 'block', height: 'auto' }}
          >
            <div className='cmp-title cds-typography  '>
              <h1>Contact Us</h1>
              <p className='muse-text-50 muse-mt-40'>
                Fill out the form and will reach out soon
              </p>
              <div
                className={
                  checkLogin ? 'displayNone' : 'displayBlock info muse-mt-40'
                }
              >
                <p className='informationPara'>
                  You can get help faster and easier if you{' '}
                  <a className='loginAnchar' onClick={callLoginUrl} tabIndex={0}>
                    sign in.
                  </a>
                </p>
              </div>
              <div className='manage-profile muse-mb-100 minMaxWidth880'>
                {checkLogin && (
                  <div className='muse-select muse-mt-100'>
                    <div
                      className={`muse-select-wrapper ${saveProfile && 'error'}`}
                      onClick={() => setProxyUserPopupOpen(!proxyUserPopupOpen)}
                    >
                      Create and manage case for <strong>{inputValues.firstName}{' '}{inputValues.lastName}</strong> ({inputValues.email})
                      <span>{proxyUserPopupOpen ? <BsChevronUp /> : <BsChevronDown />}</span>
                    </div>
                    {hasProxyUserVerified && <button className='muse-button-secondary muse-button-secondary--small cursor-pointer muse-ml-40' onClick={() => handleProxyUser('Remove')}>Remove</button>}
                  </div>
                )}
                {checkLogin && saveProfile && (showValidateUser())}

                {proxyUserPopupOpen && (
                  <div className='proxy-user-popup'>
                    <div tabIndex={0} style={{ height: '25px' }}>
                      <button onClick={() => handleProxyUser('Close')} className="modal-close-button" style={{ marginLeft: 'auto' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none"><path fill="currentColor" d="m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z"></path></svg>
                      </button>
                    </div>

                    <div>Add Proxy User</div>
                    <div className={`input-field ${error?.proxyEmail && 'muse-input--error'}`}>
                      <div className='muse-input-wrapper'>
                        <input
                          type='text'
                          name='proxyEmail'
                          id='proxyEmail'
                          value={inputValues.proxyEmail}
                          onChange={(e) => handleChange(e)}
                          autoFocus
                          aria-required='true'
                          autoComplete='off'
                          aria-label='Proxy Email'
                          placeholder='Type mail address'
                        />
                      </div>
                    </div>
                    {error?.proxyEmail && (
                      <ErrorLabel
                        class='muse-input-caption'
                        error={error?.proxyEmail}
                      />
                    )}
                    <button className={`muse-button-primary muse-button-primary--small cursor-pointer ${error?.proxyEmail && 'muse-disabled-button'}`} style={{ float: 'right' }}
                      onClick={() => handleProxyUser('Save')}>
                      Save Proxy User
                    </button>
                  </div>
                )}
              </div>
              <div
                className={
                  checkLogin
                    ? 'borderBottom muse-mt-100'
                    : 'borderBottom muse-mt-60'
                }
              ></div>
            </div>
            <div className='muse-mt-130 minMaxWidth550'>
              {/* First Name */}
              <div
                className={`muse-input ${error?.firstName && 'muse-input--error'
                  } `}
              >
                <div
                  className='muse-input-wrapper'
                  style={checkLogin ? { background: '#f9f8f8' } : {}}
                >
                  <label className='muse-input-label' htmlFor='firstName'>
                    First Name*
                  </label>
                  <input
                    type='text'
                    className='muse-input-field maxWidth95'
                    name='firstName'
                    id='firstName'
                    maxLength={50}
                    value={inputValues.firstName}
                    onChange={(e) => handleChange(e)}
                    autoComplete='off'
                    disabled={checkLogin ? true : false}
                    autoFocus
                    aria-required='true'
                    aria-label='First Name'
                  />
                </div>
                {error?.firstName && (
                  <ErrorLabel
                    class='muse-input-caption'
                    error={error?.firstName}
                  />
                )}
              </div>
              {/* Last Name */}
              <div className='muse-input muse-mt-100'>
                <div
                  className={`muse-input ${error?.lastName && 'muse-input--error'
                    } `}
                >
                  <div
                    className='muse-input-wrapper'
                    style={checkLogin ? { background: '#f9f8f8' } : {}}
                  >
                    <label className='muse-input-label' htmlFor='lastName'>
                      Last Name*
                    </label>
                    <input
                      type='text'
                      className='muse-input-field maxWidth95'
                      name='lastName'
                      id='lastName'
                      maxLength={50}
                      value={inputValues.lastName}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      disabled={checkLogin ? true : false}
                      aria-required='true'
                      aria-label='Last Name'
                    />
                  </div>
                  {error?.lastName && (
                    <ErrorLabel
                      class='muse-input-caption'
                      error={error?.lastName}
                    />
                  )}
                </div>
              </div>
              {/* Email */}
              <div className='muse-input muse-mt-100'>
                <div
                  className={`muse-input ${(error?.email || saveProfile) && 'muse-input--error'
                    } `}
                >
                  <div
                    className='muse-input-wrapper'
                    style={checkLogin ? { background: '#f9f8f8' } : {}}
                  >
                    <label className='muse-input-label' htmlFor='email'>
                      Email Address*
                    </label>
                    <input
                      type='text'
                      className='muse-input-field maxWidth95'
                      name='email'
                      id='email'
                      maxLength={41}
                      value={inputValues.email}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      disabled={checkLogin ? true : false}
                      aria-required='true'
                      aria-label='Email Address'
                    />
                  </div>
                  {error?.email && (
                    <ErrorLabel
                      class='muse-input-caption'
                      error={error?.email}
                    />
                  )}  
                </div>
                {
                  inputValues.email && !error?.email && !checkLogin && dialogState.type !== 'verified' && (
                    showValidateUser()
                  )
                }
              </div>
              {/* Addtional Email Receipients */}
              <div className='muse-input muse-mt-100'>
                <div className={`muse-input ${(error?.additionalEmail) && 'muse-input--error'} `}>
                  <div
                    className='muse-input-wrapper'>
                    <label className='muse-input-label' htmlFor='additionalEmail'>
                      Additional Email Recipients (optional)
                    </label>
                    <input
                      type='email'
                      className='muse-input-field maxWidth95'
                      name='additionalEmail'
                      id='additionalEmail'
                      // maxLength={41}
                      value={inputValues.additionalEmail}
                      onChange={(e) => handleChange(e)}
                      onKeyDown={handleKeyDown}
                      autoComplete='off'
                      aria-required='false'
                      aria-label='Additional Email Recipients'
                      multiple
                    />
                  </div>
                  {error?.additionalEmail && (
                    <ErrorLabel
                      class='muse-input-caption'
                      error={error?.additionalEmail}
                    />
                  )}
                  <div className='additional-email-list'>
                    {additionalEmailList.slice(0, showAllEmail ? additionalEmailList.length : 2).map((email, index) => (
                      <span key={index}>
                        {email}
                        <BsXLg className='icon' onClick={(e) => handleRemoveEmail(e, index)} />
                      </span>
                    ))}
                    {additionalEmailList.length > 2 && !showAllEmail && (
                      <span className='cursor-pointer' onClick={() => setShowAllEmail(!showAllEmail)}>
                        +{additionalEmailList.length - 2}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* Issue Category */}
              <div className='muse-select muse-mt-100'>
                <div
                  className={`muse-input ${error?.issueCategory && 'muse-select--error'
                    } `}
                >
                  <div className='muse-select-wrapper'>
                    <label
                      className='muse-select-label'
                      htmlFor='issueCategory'
                    >
                      Issue Category*
                    </label>
                    <select
                      name='issueCategory'
                      id='issueCategory'
                      className='muse-select-field'
                      //onChange={e => setSelectedCategory(e.target.value)}
                      value={inputValues.issueCategory}
                      onChange={(e) => handleChange(e)}
                      aria-required='true'
                      aria-describedby="issueCategoryError"
                    >
                      <option value=''>Select</option>
                      <option value='Cisco.com Profile / Account and Password Issues'>
                        Cisco.com Profile / Account and Password Issues{' '}
                      </option>
                      <option value='Contracts and Contract Association'>
                        Contracts & Contract Association
                      </option>
                      <option value='Software Download'>
                        Software Download
                      </option>
                      <option value='Networking Academy, Training and Certifications'>
                        Networking Academy, Training & Certifications
                      </option>
                      <option value='Other Categories'>Other Categories</option>
                    </select>
                  </div>
                  {error?.issueCategory && (
                    <ErrorLabel
                      id='issueCategoryError'
                      class='muse-select-caption'
                      error={error?.issueCategory}
                    />
                  )}
                </div>
              </div>
              {inputValues.issueCategory ===
                'Cisco.com Profile / Account and Password Issues' && (
                  <div className='muse-select muse-mt-100'>
                    <div
                      className={`muse-input ${error?.issueSubCategory && 'muse-select--error'
                        } `}
                    >
                      <div className='muse-select-wrapper'>
                        <label
                          className='muse-select-label'
                          htmlFor='issueSubCategory'
                        >
                          Issue Subcategory*
                        </label>
                        <select
                          name='issueSubCategory'
                          id='issueSubCategory'
                          className='muse-select-field'
                          defaultValue={
                            inputValues.issueSubCategory
                              ? inputValues.issueSubCategory
                              : ''
                          }
                          onChange={(e) => handleChange(e)}
                          aria-required='true'
                          aria-label='Issue Subcategory'
                          aria-describedby="issueSubCategoryError"
                        >
                          <option value=''>Select</option>
                          <option value='Password Reset and Recovery'>
                            Password Reset & Recovery
                          </option>
                          <option value='Account Login Error Messages'>
                            Account Login Error Messages
                          </option>
                        </select>
                      </div>
                      {error?.issueSubCategory && (
                        <ErrorLabel
                          id='issueSubCategoryError'
                          class='muse-select-caption'
                          error={error?.issueSubCategory}
                        />
                      )}
                    </div>
                  </div>
                )}

              {inputValues.issueCategory ===
                'Contracts and Contract Association' && (
                  <div className='muse-select muse-mt-100'>
                    <div
                      className={`muse-input ${error?.issueSubCategory && 'muse-select--error'
                        } `}
                    >
                      <div className='muse-select-wrapper'>
                        <label
                          className='muse-select-label'
                          htmlFor='issueSubCategory'
                        >
                          Issue Subcategory*
                        </label>
                        <select
                          name='issueSubCategory'
                          id='issueSubCategory'
                          className='muse-select-field'
                          defaultValue={
                            inputValues.issueSubCategory
                              ? inputValues.issueSubCategory
                              : ''
                          }
                          onChange={(e) => handleChange(e)}
                          aria-describedby="issueSubCategoryError"
                        >
                          <option value=''>Select</option>
                          <option value='Add Contract to User'>
                            Add Contract to User
                          </option>
                          <option value='Remove Contract from User'>
                            Remove Contract from User
                          </option>
                          <option value='Contract Changes and Updates'>
                            Contract Changes & Updates
                          </option>
                        </select>
                      </div>
                      {error?.issueSubCategory && (
                        <ErrorLabel
                          id='issueSubCategoryError'
                          class='muse-select-caption'
                          error={error?.issueSubCategory}
                        />
                      )}
                    </div>
                  </div>
                )}

              {inputValues.issueCategory === 'Networking Academy, Training and Certifications' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${error?.issueSubCategory && 'muse-select--error'
                      } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Select</option>
                        <option value='Networking Academy'>
                          Networking Academy
                        </option>
                        <option value='Training and Certification (Learning@Cisco)'>
                          Training and Certification (Learning@Cisco)
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}

              {inputValues.issueCategory === 'Software Download' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${error?.issueSubCategory && 'muse-select--error'
                      } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Select</option>
                        <option value='Find Software Image'>
                          Find Software Image
                        </option>
                        <option value='Access to Download Software Image'>
                          Access to Download Software Image
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}

              {(!['Contract Changes and Updates', 'Networking Academy', 'Training and Certification (Learning@Cisco)'].includes(showValue)) && (
                <div className='muse-input muse-mt-100'>
                  <div
                    className={`muse-input ${error?.description && 'muse-input--error'
                      } `}
                  >
                    <div
                      className='muse-input-wrapper'
                      style={{ height: '100px' }}
                    >
                      <label className='muse-input-label' htmlFor='description'>
                        Description*
                      </label>
                      <textarea
                        className='muse-input-field'
                        name='description'
                        id='description'
                        placeholder='Please enter description with minimum 4 characters'
                        maxLength={32001}
                        value={inputValues.description}
                        onChange={(e) => handleChange(e)}
                        style={{ resize: 'none', maxWidth: '95%' }}
                        aria-label='Description'
                        aria-describedby='Please enter description with minimum 4 characters'
                      />
                    </div>
                    {error?.description && (
                      <ErrorLabel
                        class='muse-input-caption'
                        error={error?.description}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="captchaV2Holder" style={captchaVersion === 2 ? { display: '' } : { display: 'none' }}>
                <RecaptchaV2 captchaCreated={handleCaptcha} />
                <ErrorLabel
                  class='muse-input-caption'
                  error='Please complete the captcha challenge'
                />
              </div>

              {showNotification && (<div className="subNotification" id="subCategoryLinkNotification">
                <div id="subCategoryLinkNotificationinnerDiv" className='subLinkNotificationinnerDiv'>
                  <p>For support related to <b>{inputValues.issueSubCategory}</b>, please visit: </p>
                  <p><a href={showSubLinks} rel="noreferrer" target='_blank'>{showSubLinks}</a></p>
                </div>
              </div>)}

              <div>
                {/*onClick={handleOpen}*/}
                <button
                  onClick={hasVerifiedEmail}
                  className='muse-button-primary muse-mt-150 cursorPointer muse-disabled-button'
                  id='btn_submit'
                >
                  Submit Inquiry
                </button>
                <Link
                  to='/'
                  className='muse-button-secondary muse-ml-100 cursorPointer'
                >
                  Cancel
                </Link>
              </div>

              {/* Displays a popup upon submitting the enquiry */}
              <CommonModalBox
                isOpen={isModalOpen}
                onClose={() => handleOpen(false)}
                title={renderEnquiryModal('title')}
                buttons={renderEnquiryModal('button')}
                width={40}
                disableClose={false}
              >
                {renderEnquiryModal('content')}
              </CommonModalBox>

              <div className='muse-mt-150 minMaxWidth550'>
                {
                  (inputValues.issueCategory ===
                    'Cisco.com Profile / Account and Password Issues' || inputValues.issueCategory === 'Contracts and Contract Association') && (
                    <div>
                      {
                        showInfoPopup && <ModalBox ModalOpen={handleInfoModal} From={'pw-notification'} />
                      }
                    </div>
                  )
                }
              </div>

              {dialogState.open && <ModalBox ModalOpen={handleDialogState} From="verifyEmail" data={inputValues.email} />}
            </div>
          </div>
        </div>
      </div>
      {/* Opens Modal when the form is submitted without authentication */}
      <CommonModalBox
        isOpen={showAuthPopup}
        onClose={() => setShowAuthPopup(false)}
        title={''}
        buttons={renderButton()}
        width={30}
        disableClose={false}
      >
        For quicker service please authenticate your email before proceeding.
      </CommonModalBox>
    </>
  );
}
